.basket_big_title {
  color: #1A1A1A;
  font-family: Heebo;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.basket_no_data {
  color: var(--csk-000000600, #666);
  font-family: Heebo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  margin-top: 48px;
}

.recomendation_title {
  color: var(--csk-000000900, #1A1A1A);
  font-family: Heebo;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 108px;
  margin-bottom: 32px;
}

.basket_card {
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-top: 36px;
  margin-bottom: 36px;
}

.basket_card_img {
  width: 11.375661375661375vw;
  height: 13.227513227513228vw;
  flex-shrink: 0;
  background-color: #E9E9E9;
}

.basket_card_name {
  color: var(--neutral-800, #333);
  font-family: Heebo;
  font-size: 1.3227513227513228vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  width: 12.566137566137566vw;
}

.basket_card_price {
  color: var(--neutral-800, #333);
  font-family: Inter;
  font-size: 1.3227513227513228vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.basket_card_edit {
  color: var(--Primary-2, #22A7C1);
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
  margin-top: 17px;
  margin-left: 10px;
}

.basket_card_delete {
  color: #F94A4A;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
  margin-top: 17px;
  margin-left: 10px;
}

.basket_card_plus_minus {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: transparent;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: -0.1px;
  width: 16px;
  height: 16px;
  color: var(--Primary-7, #688E22);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
}

.basket_card_count {
  display: flex;
  padding: 2px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--Primary-5, #688E22);
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 29px;
  height: 28px;
  margin-left: 12px;
  margin-right: 12px;
  outline: none;
}

.basket_card_price_sale {
  color: var(--neutral-400, #999);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  min-width: 100px;
  position: relative;
  /* left: 40px; */
}

.basket_info1 {
  display: flex;
  position: relative;
  left: -26px;
  z-index: 100;
  justify-content: space-between;
  width: 61.507936507936506vw;
}

.basket_card_size {
  color: var(--neutral-400, #999);
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.08px;
  margin-top: 7px;
  margin-right: 24px;
}

.basket_card_size_color {
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 0.5px solid var(--neutral-200, #CCC);
  background: var(--White, #FFF);
}

.basket_size_fat {
  position: relative;
  margin-top: -55px;
  left: 44px;
  top: 76px;
  margin-left: -70px;
  z-index: 110;
}

.basket_size_fat2 {
  position: relative;
  margin-top: -55px;
  left: 62.16931216931217vw;
  top: -20px;
  margin-left: -70px;
  z-index: 110;
}

.basket_card_size_select {
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;
}

.basket_promo_title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: -0.04px;
}

.basket_promo_text {
  color: var(--neutral-400, #999);
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  width: 320px;
  margin-top: 16px;
}

.basket_promo {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #CCC;
  width: 320px;
  padding-bottom: 8px;
  margin-top: 8px;
  outline: none;
  color: var(--neutral-300, #121E22);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 32px;
}

.basket_promo_btn {
  display: inline-flex;
  padding: 16px 48px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--White, #f1ffeb);
  box-shadow: 0px 0px 5px 1px rgba(14, 23, 26, 0.04);
  transition: all 0.3s ease;
  color: var(--primary-5, #829D50);
  font-family: 'Inter400';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
}

.basket_promo_btn:hover {
  transition: all 0.3s ease;
  border-radius: 10px;
  background: var(--primary-6, #829D50);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.20);
  color: var(--White, #f1ffeb);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.036px;
}

.basket_total {
  width: 344px;
  height: 243px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--neutral-200, #CCC);
  background: transparent;
  padding: 32px 32px 31px 32px;
  display: flex;
  justify-content: space-between;
}

.accordion-button:not(.collapsed), .accordion-item:first-of-type .accordion-button {
  color: var(--Neutral-900, #1A1A1A);
  font-family: 'Inter400';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
}

.basket_total_title {
  color: var(--neutral-600, #666);
  font-family: 'Inter500';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.basket_total_price {
  color: var(--neutral-800, #333);
  font-family: 'Inter500';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.basket_promo_btn_price {
  display: inline-flex;
  padding: 16px 46px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--primary-6, #829D50);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.20);
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  text-decoration: none;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.036px;
  border: none;
  margin-top: 33px;
}

.red-border {
  border-bottom: 1px solid red;
}

.basket_counter_fat {
  width: 381px;
  height: 357px;
  border-radius: 16px;
  border: 1px solid var(--csk-000000200, #CCC);
  background: #FFF;
  margin: 200px 120px;
  padding: 44px 57px;
}

.basket_counter_total {
  color: #000;
  text-align: center;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.basket_counter_total_price {
  color: var(--Primary-2, #22A7C1);
  font-family: Heebo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.basket_counter_total_price_black {
  color: var(--csk-000000800, #333);
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.basket_prices input[type="checkbox"] {
  width: 24px;
  height: 24px;
  clip-path: circle(46% at 50% 50%);
  accent-color: #4DAD1F !important;
  color: #fff !important;
  opacity: 1 !important;
}

.basket_card_checkbox_wrapper {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid var(--csk-000000200, #CCC);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 120px;
}

.choose_all input[type="checkbox"] {
  width: 24px;
  height: 24px;
  clip-path: circle(46% at 50% 50%);
  accent-color: #4DAD1F !important;
  color: #fff !important;
  opacity: 1 !important;
}

.choose_all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose_all_title {
  color: var(--csk-000000900, #1A1A1A);
  font-family: Heebo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  margin-top: 2px;
}

.choose_all_checkbox_wrapper {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid var(--csk-000000200, #CCC);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
}

.basket_counter12 {
  padding: 4px 12px 4px 0px;
  border: 1px solid var(--neutral-100, #E6E6E6);
  background: transparent;
  min-width: 80px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.basket_counter_button {
  display: flex;
  width: 267px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--Primary-2, #22A7C1);
  color: var(--csk-000000900, #1A1A1A);
  text-align: center;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #fff;
  margin-top: 43px;
}

.basket_order_now {
  display: flex;
  width: 267px;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: var(--Primary, #4DAD1F);
  box-shadow: 0px 1px 2px 0px rgba(53, 29, 0, 0.20);
  color: #FFF;
  font-family: Heebo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.09px;
  border: none;
  margin-top: 20px;
}

.basket_wrapper {
  width: 100%;
  /* height: 983px; */
  background-color: #ffffff;
  padding: 21px 64px;
  position: relative;
  z-index: 90;
}

.color_border_basket {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 1px solid #4D4D4D;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease;
  cursor: pointer;
}

.color_basket {
  width: 24px;
  height: 24px;
  border: 3px solid #ffffff;
  border-radius: 50%;
  background-color: #F87C7C;
}

.basket_item {
  color: var(--neutral-400, #999);
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.08px;
}

.selected-color {
  border: 2px solid #4D4D4D; /* Set the border style for the selected color */
}

input[type=checkbox] {
  position: relative;
  cursor: pointer;
}

/* Unchecked */

input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: -5px;
  border-radius: 6px;
  left: -4px;
  background-color: #e9e9e9;
}

/* Checked */

input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: -4px;
  left: -4px;
  background-color: #1E80EF;
  border-radius: 6px;
}

/* Verifed icon */

input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 9px;
  height: 18px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: -3px;
  left: 4px;
}

.basket_check {
  color: var(--Neutral-700, #4D4D4D);
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.08px;
  margin: 0;
  padding: 0;
  position: relative;
  top: -1px;
}