.header_main {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 1000000;
  background-color: #fff;
}

.visible {
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden {
  top: -80px;
  transition: top 0.4s ease-out;
}

.visible_mobile {
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden_mobile {
  top: -80px;
  transition: top 0.4s ease-out;
}

.dropdown-item:hover, .nav-link:hover {
  color: #32444B !important;
}

.nav-link {
  color: var(--primary-8, #121E22) !important;
  font-family: 'Inter400' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; 
  cursor: pointer !important;
}

.header_search {
  border-radius: 10px;
  border: 2px solid #E6E6E6;
  padding: 3px 9px;
  margin-right: 110px;
  height: 40px;
  margin-top: 6px;
}

.header_logo {
  width: 159px;
  height: 61px;
  position: relative;
  z-index: 1000;
}

.header_search_input {
  border: none;
  background-color: transparent;
  outline: none;
  width: 150px;
  /* width: 150px; */
  color: var(--Neutral-100, #121E22);
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  padding-top: 5px;
}

.header_search_icon {
  width: 21px;
  height: 20px;
}

.header_search_input::placeholder {
  color: var(--Neutral-100, #E6E6E6);
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  padding-top: 5px;
}

.language_icon {
  width: 20px;
  height: 20px;
}

.basket_counter_father {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: transparent;
  margin-right: 26px;
  text-decoration: none;
  margin-top: 8px;
  margin-left: 14px;
}

.basket_counter {
  display: flex;
  width: 18px;
  height: 18px;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 9px;
  background: #F94A4A;
  color: #FFF;
  font-family: Montserrat Alternates;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  z-index: 2;
  margin-left: 28px;
  margin-top: -8px;
  justify-content: center;
  align-items: center;
}

.user_name_text {
  color: var(--primary-93, #061127);
  font-family: 'Inter400';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  margin-top: 0px;
  margin-left: 4px;
}

/* modal */

.register_title {
  color: var(--neutral-800, #121212);
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 114.286% */
  letter-spacing: -0.448px;
}

.register_text {
  color: var(--neutral-500, #666);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.register {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  border: none;
  border-radius: 12px;
  background: var(--primary-6, #829D50);
  margin-top: 32px;
  margin-bottom: 16px;
  width: 100%;
}

.login {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: var(--primary-7, #688E22);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  border: none;
  border-radius: 12px;
  background: var(--Neutral-50, #F8F8F8);
  width: 100%;
}

.register_input {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--neutral-50, #e8e8e8);
  border: none;
  outline: none;
}

.register_in_text {
  color: var(--neutral-800, #121212);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.register_and_text {
  color: var(--neutral-400, #999);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 3px;
}

.register_text_no_password {
  color: #688E22;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.language_list {
  background: var(--White, #FFF);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
}

.language_item {
  display: flex;
  width: 130px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--neutral-50, #ffffff);
  background: var(--White, #FFF);
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.language_list_31 {
  margin-left: -45.24px;
}

.language_list_32 {
  margin-left: -31.24px;
}

.language_list_33 {
  margin-left: -49.24px;
}

.navbar {
  background-color: #fff !important;
}

/* .language_item:hover {
  transform: scale(1.1);
  transition: all 0.2s ease;
} */

.language_list {
  display: none !important;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  z-index: 200;
}

.nav-item:hover .language_list {
  display: block !important;
  transform: translateY(0);
  transition: all 0.3s ease;
}

.language_item {
  margin-bottom: 8px;
  color: currentColor;
  text-decoration: none;
  transition: all 0.1s ease;
  border-radius: 0;
}

.nav-item {
  position: relative;
}

.password-error {
  border: 1px solid #ff0000;
}

.nav-link:hover {
  opacity: 0.8;
}

.language_item:hover {
  /* border-bottom: 1px solid #000; */
  /* transform: rotate(10deg); */
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.1s ease;
  border-radius: 10px;
}

.register_title2_forget {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #282828;  
}