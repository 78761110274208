.profile_page {
  width: 90%;
  background-color: #FFFFFF;
  padding: 16px;
  margin-top: 29px;
  margin-bottom: 60px;
}

.profile_page_title {
  font-family: 'Inter500';
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;  
  margin-left: 20px;
  margin-top: 32px;
}