.order_name_mobile {
  width: 176px;
  height: 40px;  
  font-family: 'Inter400';
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;  
}

.accordion_color {
  background-color: #ffffff00 !important;
  border: 1px solid #ffffff00;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: inherit;
}

button.accordion-button:focus{
  box-shadow: inherit;
}

button.accordion-button:focus{
  box-shadow: none;
  outline: none; 
}

.accordion-button::after {
  width: 24px;
  height: 24px;
}