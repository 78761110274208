.auth_title {
  width: 155px;
  height: 32px;  
  font-family: 'Inter600';
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.002em;
  text-align: left;  
  margin-top: 60px;
}

.auth_text {
  width: 319px;
  height: 24px;  
  font-family: 400;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;  
  margin-top: 12px;
}

.auth_button_reg {
  width: 80%;
  height: 48px;
  border-radius: 10px;
  background: #829D50;
  border: none;
  font-family: 'Inter500';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #FFFFFF;
  margin-top: 40px;
}

.auth_button_log {
  width: 80%;
  height: 48px;
  border-radius: 10px;
  background: #92A86A;
  border: none;
  font-family: 'Inter500';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;  
  color: #ffffff;
  margin-top: 20px;
}

.forget_mobile_title2 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #333333;
  margin-bottom: 24px;  
}