.profile_header {
  width: 340px;
  height: 600px;
  flex-shrink: 0;
  background: var(--White, #ffffff);
  padding: 24px;
}

.user_image {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

.user_name {
  color: var(--primary-7, #17262B);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 200% */
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.profile_header_text_active {
  color: var(--primary-7, #18356D);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-left: 16px;
}

.profile_header_text {
  color: var(--neutral-600, #666);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-left: 16px;
}

.profile_item {
  cursor: pointer;
}

.logout_h2 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #17262B;  
  margin-top: 12px;
}

.logout_button {
  width: 250px;
  height: 44px;
  padding: 12px 16px 12px 16px;
  border-radius: 10px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;  
  color: #1C471F;
  border: none;
  background-color: #C9DA8F;
  margin-top: 48px;
  margin-bottom: 12px;
}