.footer_bar {
  width: 100%;
  height: 65px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  padding-top: 12px;
}

.footer_bar_text {
  font-family: Heebo;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: center;
  color: #B3B3B3;  
  text-decoration: none;
}

.footer_bar_text_active {
  font-family: Heebo;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: center;
  color: #688E22;  
  text-decoration: none;
}

.footer_bar_text_count {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #F94A4A;
  font-family: Montserrat Alternates;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;  
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}