.drawing-area {
  position: absolute;
  top: 130px;
  left: 180px;
  z-index: 10;
}

.canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

#tshirt-div {
  display: flex;
  justify-content: space-between;
  width: 600px;
  position: absolute;
}

#canvas {
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: default;
}
