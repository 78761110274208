.notWorkingTitle {
  color: var(--primary-7, #688E22);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
  margin-top: 36px;
}

.notWorkingText {
  color: var(--neutral-600, #666);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin-top: 20px;
}

.notWorkingButton {
  display: inline-flex;
  padding: 12px 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--primary-6, #829D50);
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
  text-decoration: none;
  margin-top: 36px;
  margin-bottom: 50px;
}

.notWorkingButton:hover {
  color: var(--White, #FFF);
}

.notWorkingButton:hover {
  background: var(--Primary-5, #92A86A);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.20);
}

.notWorkingButton:active {
  background: var(--Primary-7, #688E22);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.20);
}