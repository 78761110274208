.user_address_mobile {
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: #F8F8F8;
  font-family: 'Inter400';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;  
}