.categories_title {
  font-family: 'Inter500';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;  
  color: #92A86A;
  text-decoration: none;
}

.categories_title_link_sub {
  color: #92A86A;
  text-decoration: none;
}

.categories_title_link_sub:hover {
  color: #0F7CF4;
  text-decoration: none;
}

.categories_quant {
  font-family: 'Inter400';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;  
  color: #999999;
}