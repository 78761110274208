.toast_title {
  color: #17262B;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 261px;
}

.toast_description {
  color: var(--Neutral-600, #666);
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.032px;
  width: 330px;
}

.toast_link {
  color: var(--Primary-6, #829D50);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  position: relative;
  top: -10px;
}

:root {
  --toastify-toast-width: 710px;
}