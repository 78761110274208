@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap');

.footer {
  width: 100%;
  height: 320px;
  background-color: #F8F8F8;
}

.footer_logo {
  color: #5C16EB;
  text-align: center;
  font-family: 'Jost';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 100% */
}

.footer_title {
  color: var(--Neutral-900, #1A1A1A);
  font-family: 'Inter500';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.footer_text {
  color: var(--Neutral-900, #1A1A1A);
  font-family: 'Inter400';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  margin-left: -30px;
  opacity: 0.7;
  transition: all 0.1s ease;
  cursor: pointer;
  text-decoration: none;
}

.footer_text a {
  color: currentColor;
  text-decoration: none;
}

.footer_text:hover {
  opacity: 1;
  transition: all 0.1s ease;
}

.footer_text_data {
  color: var(--Neutral-300, #B3B3B3);
  text-align: center;
  font-family: 'Inter400';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}

.footer_text_data a {
  color: currentColor;
  text-decoration: none;
}

.footer_text_data:hover {
  opacity: 1;
  transition: all 0.1s ease;
}

.footer_laws {
  color: #CCC;
  font-family: Heebo;
  font-size: 18px;
  font-style: normal;
  margin-top: 30px;
  position: relative;
  left: -112px;
}

.flaap_modal {
  --bs-modal-bg: transparent !important;
  --bs-modal-border-color: transparent !important;
}

.modal_footer_input {
  width: 346px;
  height: 48px;
  border: none;
  outline: none;
  background-color: #ffffff;
  padding: 14px 16px;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;  
  color: #626262;
  margin-bottom: 14px;
  border-radius: 8px;
  resize: none;
}

.modal_footer_input_button {
  width: 346px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
}