.clothes_image {
  mix-blend-mode: multiply;
  width: 600px;
}

.white_background {
  background-color: #ffffff;
  width: 828.88px;
  height: 100px;
  position: relative;
  margin-top: -99px;
  padding-top: 29px;
  padding-left: 640px;
}

.addToBasket_image {
  width: 229px;
  height: 48px;
}

.layers {
  display: inline-flex;
  width: 305px;
  height: 730px;
  padding: 24px 60px 60px
    60px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid var(--neutral-100, #e6e6e6);
  background: var(--neutral-50, #f8f8f8);
  text-align: center;
  overflow-x: scroll;
}

.layers_right {
  display: inline-flex;
  width: 305px;
  height: 730px;
  padding: 24px 60px 60px
    60px;
  flex-direction: column;
  align-items: center;
  gap: 200px;
  flex-shrink: 0;
  border: 1px solid var(--neutral-100, #e6e6e6);
  background: var(--neutral-50, #f8f8f8);
  text-align: center;
  overflow-x: scroll;
}

.layers:nth-child(even) {
  padding: 24px 60px 60px
    40px !important;
  overflow-y: hidden !important;
}

.shirt_drawing {
  width: 100%;
  background-color: #f0f1f5;
  height: 824px;
}

.shirt_drawing_header {
  width: 100%;
  height: 36px;
  border: 1px solid var(--neutral-100, #e6e6e6);
  background: var(--neutral-50, #ffffff);
  display: flex;
}

.shirt_drawing_header_select {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral-100, #e6e6e6);
  background: var(--neutral-50, #ffffff);
  outline: none;
  color: var(--primary-7, #18356d);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: -1px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
  cursor: pointer;
}

.shirt_drawing_header_select:active {
  border: 1px solid var(--primary-6, #829d50);
  background: var(--primary-6, #829d50);
  color: #fff;
  transition: all 0.3s ease;
}

.shirt_drawing_header_select:active svg path {
  fill: var(--White, #fff);
  transition: all 0.3s ease;
}

.shirt_drawing_header_select:hover {
  border: 1px solid var(--primary-6, #829d50);
  background: var(--primary-6, #829d50);
  color: #fff;
  transition: all 0.3s ease;
}

.shirt_drawing_header_select:hover svg path {
  fill: var(--White, #fff);
  transition: all 0.3s ease;
}

.category_change:hover,
.color_change_selector:hover {
  background: var(--primary-6, #829d50);
  color: #fff;
  transition: all 0.3s ease;
}

.category_change:hover svg path {
  fill: var(--White, #fff);
  transition: all 0.3s ease;
}

.layers_text_fat {
  color: var(--Primary-7, #3064cc);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.1px;
}

.layers_text {
  color: var(--neutral-400, #999);
  text-align: center;
  font-family: "Inter400";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.08px;
  width: 184px;
}

.add_text {
  outline: none;
  font-style: normal;
  font-weight: 400;
  border: none;
  border: 1px solid green;
}

.add_text::placeholder {
  color: var(--neutral-950, #0d0d0d);
  font-family: "Inter400";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.circle_image img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 395px;
  margin-left: 210px;
  z-index: 100;
}

.size_image img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 439px;
  margin-left: 412px;
  z-index: 100;
}

.layers_div {
  display: flex;
  width: 252px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: #ebf2ff;
  cursor: pointer;
}

.layers_text_value {
  color: var(--White, #18356d);
  font-family: "Inter400";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.08px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 145px;
  text-align: left;
  margin-top: 6px;
}

.MuiSlider-valueLabelLabel {
  width: 42px !important;
  height: 38px !important;
  color: var(--primary-7, #3064cc) !important;
  font-family: Heebo !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 12px !important;
}

.MuiSlider-valueLabel {
  background-color: #fff !important;
  fill: var(--neutral-50, #ffffff) !important;
  filter: drop-shadow(2px 3px 15px rgba(0, 0, 0, 0.1))
    drop-shadow(-2px 0px 15px rgba(0, 0, 0, 0.1)) !important;
}

.selcet_option_layer {
  display: flex;
  width: 252px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--neutral-200, #ccc);
  background: var(--White, #fff);
  color: var(--primary-6, #17262b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.08px;
}

.modal_content_print {
  width: 900px !important;
  height: 650px !important;
}

.color_change_selector {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-top: 0.2px solid var(--neutral-100, #e6e6e6);
  border-right: 0.2px solid var(--neutral-100, #e6e6e6);
  border-bottom: 0.2px solid var(--neutral-100, #e6e6e6);
  border-left: 1px solid var(--neutral-100, #e6e6e6);
  background: var(--neutral-50, #f8f8f8);
  width: 52px;
  height: 43px;
  color: var(--primary-6, #18356d);
  font-family: "Inter400";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.13px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

.color_change_selector_modal {
  display: flex;
  width: 80px;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: var(--Primary-7, #3064cc);
  font-family: "Inter400";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  border: 1px solid var(--Neutral-200, #ccc);
  background: var(--White, #fff);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.category_change {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  border-top: 1px solid var(--neutral-100, #e6e6e6);
  border-right: 1px solid var(--neutral-100, #e6e6e6);
  border-bottom: 0.2px solid var(--neutral-100, #e6e6e6);
  border-left: 1px solid var(--neutral-100, #e6e6e6);
  background: var(--White, #fff);
  width: 150px;
  height: 36px;
  color: var(--primary-7, #17262b);
  font-family: "Inter400";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.08px;
  cursor: pointer;
}

.category_change_disbaled {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  border-top: 1px solid var(--neutral-100, #e6e6e6);
  border-right: 1px solid var(--neutral-100, #e6e6e6);
  border-bottom: 0.2px solid var(--neutral-100, #e6e6e6);
  border-left: 1px solid var(--neutral-100, #e6e6e6);
  background: var(--White, #fff);
  width: 150px;
  height: 36px;
  color: var(--primary-7, #17262b63);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.08px;
  background-color: #f3f3f3;
  cursor: pointer;
}

.modal_size_title {
  color: var(--Neutral-600, #666);
  font-family: "Inter400";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.032px;
}

.btn_library {
  display: inline-flex;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--Primary-6, #829d50);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.2);
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: none;
  margin-top: 40px;
}

.btn_library_disabled {
  display: inline-flex;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--Neutral-300, #b3b3b3);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.1);
  color: var(--Neutral-50, #f8f8f8);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: none;
  margin-top: 40px;
}

.selected-image_modal {
  border: 2px solid var(--Primary-6, #6396fc);
}

.modal_image_title {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;  
  margin-top: 10px;
  margin-bottom: 3px;
  color: #000000;
}

.modal_image_title_price {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;  
  color: #666666;
}

.modal_image_title_button {
  width: 148px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px 0px #0000000D;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #333333;
  background-color: transparent;
  margin-top: 20px;
  margin-bottom: 40px;
}

.modal_image {
  transition: all 0.2s ease;
}

.modal_image:hover {
  transform: scale(1.2);
  transition: all 0.2s ease;
}