.info_profile {
  width: 824px;
  min-height: 600px;
  flex-shrink: 0;
  background: var(--White, #ffffff);
  padding: 32px;
}

.input_profile {
  display: flex;
  width: 300px;
  padding: 12px;
  align-items: center;
  border-radius: 12px;
  background: var(--neutral-50, #F8F8F8);
  outline: none;
  border: none;
  margin: 24px 24px 24px 0px;
}

.info_profile::placeholder {
  color: var(--neutral-200, #CCC);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.08px;
}

.btn_profile {
  display: inline-flex;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--primary-6, #829D50);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.20);
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  border: none;
}

.no_address_text {
  color: var(--neutral-400, #999);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.08px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.no_address_button {
  display: inline-flex;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--primary-6, #829D50);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.20);
  border: none;
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}

.no_address_button span {
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  text-decoration: none;
}

.no_address_button:hover svg {
  margin-left: 10px;
  transition: 0.3s;
}

.no_address_button svg {
  transition: all 0.3s ease;
}

.no_address_button:hover, .btn_profile:hover, .hero_button:hover {
  background: var(--Primary-5, #92A86A);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.20);
}

.no_address_button:active, .btn_profile:active, .hero_button:active {
  background: var(--Primary-7, #688E22);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.20);
}

.modal_title {
  color: var(--neutral-900, #1A1A1A);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  text-align: center;
}

.address_modal_text {
  color: var(--neutral-800, #333);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  margin-top: 15px;
  /* margin-right: 20px; */
}

.user_address {
  display: flex;
  width: 620px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1.5px solid var(--neutral-100, #E6E6E6);
}

.payment_data_text {
  color: var(--neutral-800, #333);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.18px;
  margin-top: 10px;
  margin-right: 20px;
}

.input_profile_payment {
  width: 220px;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 12px;
  background: var(--neutral-50, #ffffff);
  margin-bottom: 20px;
  color: var(--neutral-700, #4D4D4D);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}

.payment_data_card_name {
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.56px;
  width: 69px;
}

.payment_data_card_date {
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  margin-top: 20px;
}

.payment_data_card_number {
  color: #FFF;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.96px;
}