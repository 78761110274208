.footer_mobile {
  margin-top: 65px;
  position: relative;
  top: -65px;
}

.footer_text_mobile {
  font-family: 'Inter400';
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;  
  text-decoration: none;
  color: #1A1A1A;
}

.footer_text_mobile a {
  text-decoration: none;
  color: #1A1A1A;
}

.footer_title_mobile {
  font-family: 'Inter600';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left; 
  margin-bottom: -3px; 
}

.footer_text_mobile_copyright {
  font-family: 'Inter400';
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;  
  color: #B3B3B3;
  margin-top: 24px;
  margin-bottom: 24px;
}

.modal_title_footer_mobile {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  margin-bottom: -23px;
  position: relative;
  top: 15px;
  z-index: 1000;
  left: 20px;
}