.splide__arrow {
  width: 60px !important;
  height: 60px !important;
  padding: 14px !important;
  border-radius: 50px !important;
  background: #FFF !important;
  box-shadow: 0px 4px 12px 0px rgba(145, 132, 109, 0.20) !important;
}

.splide__arrow--prev {
  left: 7em !important;
}

.splide__arrow--next {
  right: 9em !important;
}

.hero_title {
  color: #17262B;
  font-family: 'Inter500';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px; /* 108.333% */
  letter-spacing: -0.096px;
}

.hero_text {
  color: var(--neutral-600, #666);
  font-family: 'Inter400';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.09px;
  width: 480px;
  margin-top: 32px;
}

.hero_button {
  border-radius: 12px;
  background: var(--primary-6, #829D50);
  box-shadow: 0px 1px 2px 0px rgba(14, 23, 26, 0.30);
  display: flex;
  /* justify-content: center;
  align-items: center; */
  gap: 16px;
  border: none;
  color: var(--White, #FFF);
  font-family: 'Inter500';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  text-decoration: none;
  width: 331px;
  height: 64px;
  margin-left: 40px;
  margin-top: 40px;
  transition: all 0.3s ease;
  padding: 20px 0px 0px 30px;
}

.hero_button span {
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  text-decoration: none;
}

.hero_button:hover svg {
  margin-left: 10px;
  transition: 0.3s;
}

.hero_button svg {
  transition: all 0.3s ease;
}

.hero-fade-enter {
  opacity: 0;
  transform: translateX(50px);
}

.hero-fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.hero-fade-exit {
  opacity: 1;
  transform: translateX(0);
}

.hero-fade-exit-active {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 300ms, transform 300ms;
}

.d-flex .left-image,
.d-flex .right-image {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.d-flex .center-image {
  max-width: 300px;
  max-height: 300px;
  transition: transform 0.5s ease-in-out;
}

.d-flex .left-image:hover,
.d-flex .right-image:hover {
  opacity: 1;
}

.splide__slide {
  opacity: 0.6;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-active {
  opacity: 1;
}